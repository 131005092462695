const CandidateStatuses = {
    "awaiting client data": 1,
    "completed": 1,
    "pending manual check": 2,
    "pending f2f": 3,
    "pending f2f check": 3,
    "rejected": 4,
    "rejected, cancelled employment": 4,
    "consent not given": 5,
    "cancelled": 6,
}

export const getStatusMessage = (message) => {
    const lowerCaseMessage = message.toLowerCase();
    if (Object.keys(CandidateStatuses).indexOf(lowerCaseMessage) === -1)
        return 'errors.link-expired';
    return `rtw-status.${CandidateStatuses[lowerCaseMessage]}.description`;
}