enum Languages {
  ENGLISH = "en",
  DEUTSCH = "de",
  DUTCH = "nl",
  POLISH = "pl",
  SPANISH = "es",
  FRENCH = "fr",
}

enum IsoCountryToLanguageMap {
  DEU = Languages.DEUTSCH,
  AUT = Languages.DEUTSCH,
  NLD = Languages.DUTCH,
  POL = Languages.POLISH,
  ARG = Languages.SPANISH,
  BRA = Languages.SPANISH,
  CHL = Languages.SPANISH,
  ESP = Languages.SPANISH,
  MEX = Languages.SPANISH,
  FRA = Languages.FRENCH,
}

export const getLanguageFromIsoCountry = (isoCountry?: string) => {
  if (Object.keys(IsoCountryToLanguageMap).indexOf(isoCountry) == -1) {
    return Languages.ENGLISH;
  }
  return IsoCountryToLanguageMap[isoCountry];
};
