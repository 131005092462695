import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Onfido } from 'onfido-sdk-ui';

// Consts
import api from '../../consts/api';
import moment from 'moment';
import UserContext from '../../state-management/context/UserContext';
import { SET_SESSION_EXPIRED } from '../../state-management/actions/actionTypes';

/* const VERSION = "14.28.0"; */
const getLocaleFromLanguage = (language, extended) => ['es', 'fr', 'de', 'nl', 'pl'].indexOf(language) !== -1 ? `${language}` + `${extended ? "_" + language.toUpperCase() : ""}` : 'en_US';

const customUI = {
  colorBackgroundButtonPrimary: '#8dde43',
  colorBackgroundButtonSecondary: '#8dde43',
  colorContentInput: '#8dde43',
  colorBackgroundInput: '#8dde43',
  colorContentButtonPrimaryText: "#202020",
};

const logOnfidoError = (error, step, source, userId, applicantId, onfidoFlow) => {
  return new Promise((resolve) => {
    api.post('/logging/onfido-error', {
      error,
      errorType: `Error caught from vendor SDK in ${source} - ${step} `,
      userId,
      applicantId,
      onfidoFlow,
    }).catch(e => {
      console.log(e);
    }).finally(() => {
      resolve(true);
    })
  }
  )
}

const getCountryISOCodeForHomeCountry = (allCountries, homeCountry) => {
  const result = allCountries.find(c => c.name === homeCountry)?.iso;
  return result;
}

const VendorSdk = ({
  salesforceDocument,
  isSelfieRequired,
  onfidoApplicantId,
  onfidoDocumentType,
  setError,
  updateDocumentIndex,
  onfidoToken,
}) => {
  // Translation hook
  const { t } = useTranslation();

  const [userState, userDispatch] = useContext(UserContext);

  const onfidoFlow = [];

  useEffect(() => {
    api.post(`/logging/success`, {
      isoCountryCode: userState.user.isoCountryOfHire,
      type: onfidoDocumentType,
      uuid: userState.uuid,
    });
    if (isSelfieRequired) {
      initializeVendorSDKForSelfie();
    } else {
      initializeVendorSDKForDocument();
    }
  }, [isSelfieRequired]);

  /**
   * Keep track of users flow through the vendor SDK
   */
  addEventListener('userAnalyticsEvent', (event) => {
    const m = moment(Date.now());
    onfidoFlow.push(
      event.detail.eventName + ' | ' + m.format('YYYY-MM-DD:HH-mm-ss')
    );

    const step =
      event.detail.eventName + ' | ' + m.format('YYYY-MM-DD:HH-mm-ss');
    api
      .post(`/logging/onfido-flow`, { uuid: userState.uuid, step: step })
      .catch((error) => {
        if (error.response.data.statusCode === 403) {
          userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
        }
      });
  });

  /* console.log("LOCALE: ", userState.currentLanguage, getLocaleFromLanguage(userState.currentLanguage, true)); */

  const customLanguageOptions = {
    locale: getLocaleFromLanguage(userState.currentLanguage, true),
    /* locale: 'en_US', */
    phrases: {
      // Document Confirmation Translations
      'doc_confirmation.alert.blur_detail': t('document-capture.error.blur.description'),
      'doc_confirmation.alert.blur_title': t('document-capture.error.blur.title'),
      'doc_confirmation.alert.crop_detail': t('document-capture.error.crop.description'),
      'doc_confirmation.alert.crop_title': t('document-capture.error.crop.title'),
      'doc_confirmation.alert.glare_detail': t('document-capture.error.glare.description'),
      'doc_confirmation.alert.glare_title': t('document-capture.error.glare.title'),
      'doc_confirmation.alert.no_doc_detail': t('document-capture.error.no-document.description'),
      'doc_confirmation.alert.no_doc_title': t('document-capture.error.no-document.title'),
      'doc_confirmation.title': t('document-capture.document-confirmation.title'),
      'doc_confirmation.body_id': t('document-capture.document-confirmation.description'),
      'doc_confirmation.body_license': t('document-capture.document-confirmation.description'),
      'doc_confirmation.body_passport': t('document-capture.document-confirmation.description'),
      'doc_confirmation.body_permit': t('document-capture.document-confirmation.description'),
      'doc_confirmation.body_generic_document': t('document-capture.document-confirmation.description'),
      'doc_confirmation.button_primary_redo': t('document-capture.document-confirmation.redo'),
      'doc_confirmation.button_secondary_redo': t('document-capture.document-confirmation.redo'),
      'doc_confirmation.button_primary_upload': t('document-capture.document-confirmation.confirm'),
      'doc_confirmation.image_accessibility': t('document-capture.accessibility.photo-confirmation'),
      'doc_confirmation.body_image_medium': t('document-capture.document-confirmation.quality.medium'),
      'doc_confirmation.body_image_poor': t('document-capture.document-confirmation.quality.poor'),
      'doc_confirmation.button_primary_upload_anyway': t('document-capture.document-confirmation.upload-anyway'),

      // Document Override & Translations
      'doc_submit.title_passport': salesforceDocument.name,
      'doc_submit.title_generic_document_front': salesforceDocument.name + ' (front)',
      'doc_submit.title_generic_document_back': salesforceDocument.name + ' (back)',
      'doc_submit.title_id_front': salesforceDocument.name + ' (front)',
      'doc_submit.title_id_back': salesforceDocument.name + ' (back)',
      'photo_upload.body_passport': salesforceDocument.description,
      'photo_upload.body_id_front': salesforceDocument.description,
      'photo_upload.button_take_photo': t('document-capture.capture.take-photo'),

      // Passport Translations
      'upload_guide.button_primary': t('document-capture.capture.take-photo'),
      'upload_guide.title': t('document-capture.capture.passport.title'),
      'upload_guide.subtitle': t('document-capture.capture.passport.description'),
      'upload_guide.image_detail_cutoff_label': t('document-capture.capture.passport.tip-one'),
      'upload_guide.image_detail_blur_label': t('document-capture.capture.passport.tip-two'),
      'upload_guide.image_detail_glare_label': t('document-capture.capture.passport.tip-three'),
      'upload_guide.image_detail_good_label': t('document-capture.capture.passport.tip-four'),
      'photo_upload.body_generic_document_front': 'Provide a photo of the front of your document',
      'photo_upload.body_generic_document_back': 'Provide a photo of the back of your document',

      // Selfie Translations
      'selfie_intro.title': t('document-capture.capture.selfie.title'),
      'selfie_intro.subtitle': t('document-capture.capture.selfie.description'),
      'selfie_intro.list_accessibility': t('document-capture.capture.selfie.tip-one'),
      'selfie_intro.list_item_face_forward': t('document-capture.capture.selfie.tip-two'),
      'selfie_intro.list_item_no_glasses': t('document-capture.capture.selfie.tip-three'),
      'selfie_intro.button_primary': t('shared.continue'),

      // Selfie Capture Translations
      'selfie_capture.title': t('document-capture.capture.selfie.title'),
      'selfie_capture.button_accessibility': t('document-capture.capture.take-photo'),
      'selfie_capture.frame_accessibility': t('document-capture.accessibility.frame'),

      // Selfie Confirmation Translations
      'selfie_confirmation.image_accessibility': t('document-capture.accessibility.selfie-confirmation'),
      'selfie_confirmation.subtitle': t('document-capture.capture.selfie.confirmation.description'),
      'selfie_confirmation.title': t('document-capture.capture.selfie.confirmation.title'),

      // Selfie Alerts Translations
      'selfie_capture.alert.camera_inactive.detail': t('document-capture.alerts.camera-inactive.detail'),
      'selfie_capture.alert.camera_inactive.detail_no_fallback': t('document-capture.alerts.camera-inactive.detail-no-fallback'),
      'selfie_capture.alert.camera_inactive.title': t('document-capture.alerts.camera-inactive.title'),
      'selfie_capture.alert.camera_not_working.detail': t('document-capture.alerts.camera-not-working.detail'),
      'selfie_capture.alert.camera_not_working.detail_no_fallback': t('document-capture.alerts.camera-not-working.detail-no-fallback'),
      'selfie_capture.alert.camera_not_working.title': t('document-capture.alerts.camera-not-working.title'),
      'selfie_capture.alert.timeout.detail': t('document-capture.alerts.timeout.detail'),
      'selfie_capture.alert.timeout.title': t('document-capture.alerts.timeout.title'),

      // Permissions Translations
      'permission.body_cam': t('document-capture.permission.content'),
      'permission.button_primary_cam': t('document-capture.permission.button-text'),
      'permission.subtitle_cam': t('document-capture.permission.description'),
      'permission.title_cam': t('document-capture.permission.title'),
      'permission_recovery.title_cam': t('document-capture.permission.revoked.title'),
      'permission_recovery.subtitle_cam': t('document-capture.permission.revoked.description'),
      'permission_recovery.list_header_cam': t('document-capture.permission.revoked.tip-header'),
      'permission_recovery.list_item_action_cam': t('document-capture.permission.revoked.tip-action'),
      'permission_recovery.list_item_how_to_cam': t('document-capture.permission.revoked.tip-how-to'),
      'permission_recovery.button-primary': t('document-capture.permission.revoked.button-text'),
      'permission_recovery.info': t('document-capture.permission.revoked.info'),

      // Unsupported-Browser Translations
      'error_unsupported_browser.title_android': t('document-capture.error.unsupported-browser.title'),
      'error_unsupported_browser.title_ios': t('document-capture.error.unsupported-browser.title'),
      'error_unsupported_browser.subtitle_android': t('document-capture.error.unsupported-browser.description-android'),
      'error_unsupported_browser.subtitle_ios': t('document-capture.error.unsupported-browser.description-ios'),

      // Loading Translations
      'generic.loading': t('document-capture.loading-spinner.dont-leave'),
    },
  };

  /**
   * Configure the Onfido SDK options for Selfie
   */
  function constructDocumentType(onfidoDocumentType) {
    let result;
    const country = getCountryISOCodeForHomeCountry(userState.countries, userState.user.homeCountry);
    if (onfidoDocumentType.unknown === true) {
      result = {
        genericDocumentTypes: [
          {
            id: onfidoDocumentType.documentType,
            pages: onfidoDocumentType.documentSides,
            country
          },
        ],
        hideCountrySelection: true,
        documentTypes: {
          generic_document: {
            id: onfidoDocumentType.documentType,
            country
          },

        },
      };
    } else {
      result = {
        hideCountrySelection: true,
        documentTypes: { ...onfidoDocumentType, country },
      };
    }
    return result;
  }

  const getOptions = (optionsType) => {
    const options = {
      token: onfidoToken,
      containerId: 'onfido-mount',
      language: customLanguageOptions,
      crossDevicePolicy: 'force',
    }
    if (optionsType === 'selfie') {
      options.steps = [
        {
          type: 'face',
          options: {
            requestedVariant: 'standard',
          },
        },
      ]
    } else {
      options.steps = [
        {
          type: 'document',
          options: constructDocumentType(onfidoDocumentType),
        },
      ]
    }
    return options;
  }

  /**
   * Initialize the Onfido SDK for selfie as a separate document
   */
  const initializeVendorSDKForSelfie = async () => {
    await api.post('/logging/log', {
      log: 'initializeVendorSDKForSelfie ' + userState.uuid,
    });
    const options = getOptions('selfie');
    const instance = Onfido.init({
      ...options,
      /* version: VERSION, */
      customUI,
      onComplete: async (data) => {
        try {
          await api.post('/new-hires/check-selfie', {
            applicantId: onfidoApplicantId,
            onfidoData: data,
            salesforceDocId: salesforceDocument.id,
          });

          try {
            await instance.tearDown();
            console.log("initializeVendorSDKForSelfie tearDown");
          } catch (e) {
            await logOnfidoError(e, 'onComplete', 'initializeVendorSDKForSelfie', userState.uuid, onfidoApplicantId, onfidoFlow);
          }

          initializeVendorSDKForDocument();
          console.log("initializeVendorSDKForDocument() call");
        } catch (error) {
          await logOnfidoError(error, 'onComplete', 'initializeVendorSDKForSelfie', userState.uuid, onfidoApplicantId, onfidoFlow);

          // Add tearDown in onComplete error catch
          try {
            await instance.tearDown();
          } catch (e) {
            await logOnfidoError(e, 'onComplete', 'initializeVendorSDKForSelfie', userState.uuid, onfidoApplicantId, onfidoFlow);
          }

          setError(t('document-capture.error.server-error'));

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      },
      onError: async (error) => {
        await logOnfidoError(error, 'onError', 'initializeVendorSDKForSelfie', userState.uuid, onfidoApplicantId, onfidoFlow);

        // Add tearDown in onError
        try {
          await instance.tearDown();
        } catch (e) {
          await logOnfidoError(e, 'onError', 'initializeVendorSDKForSelfie', userState.uuid, onfidoApplicantId, onfidoFlow);
        }

        setError(t('document-capture.error.server-error'));
      },
    });
  };

  /**
   * Initialize the Onfido SDK for the upcoming document
   */
  const initializeVendorSDKForDocument = async () => {
    await api.post('/logging/log', {
      log: 'initializeVendorSDKForDocument' + userState.uuid,
    });

    const options = getOptions('doc');
    const instance = Onfido.init({
      ...options,
      /* version: VERSION, */
      customUI,
      onComplete: async (data) => {
        try {
          await api.post('/new-hires/check-document', {
            applicantId: onfidoApplicantId,
            onfidoData: data,
            salesforceDocId: salesforceDocument.id,
          });
          updateDocumentIndex();
          try {
            await instance.tearDown();
            console.log("initializeVendorSDKForDocument tearDown");
          } catch (e) {
            console.log("initializeVendorSDKForDocument Error in tearDown");
            await logOnfidoError(e, 'onComplete', 'initializeVendorSDKForDocument', userState.uuid, onfidoApplicantId, onfidoFlow);
          }
        } catch (error) {
          console.log("initializeVendorSDKForDocument in catch");
          await logOnfidoError(error, 'onComplete', 'initializeVendorSDKForDocument', userState.uuid, onfidoApplicantId, onfidoFlow);

          // Add tearDown in onComplete error catch
          try {
            await instance.tearDown();
          } catch (e) {
            await logOnfidoError(e, 'onComplete', 'initializeVendorSDKForDocument', userState.uuid, onfidoApplicantId, onfidoFlow);
          }

          setError(t('document-capture.error.server-error'));

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      },
      onError: async (error) => {
        console.log("initializeVendorSDKForDocument onError");
        await logOnfidoError(error, 'onError', 'initializeVendorSDKForDocument', userState.uuid, onfidoApplicantId, onfidoFlow);

        try {
          console.log("initializeVendorSDKForDocument tearDown");
          await instance.tearDown();
        } catch (e) {
          await logOnfidoError(e, 'onError', 'initializeVendorSDKForDocument', userState.uuid, onfidoApplicantId, onfidoFlow);
          console.log("initializeVendorSDKForDocument Error in tearDown 2");
        }

        setError(t('document-capture.error.server-error'));
      },
    });
  };

  return <div id="onfido-mount" className={`${userState.currentLanguage}`} />;
};

VendorSdk.propTypes = {
  isSelfieRequired: PropTypes.bool,
  onfidoApplicantId: PropTypes.string,
  onfidoDocumentType: PropTypes.object,
  onfidoToken: PropTypes.string,
  salesforceDocument: PropTypes.object,
  setError: PropTypes.func,
  updateDocumentIndex: PropTypes.func,
};

VendorSdk.defaultProps = {
  isSelfieRequired: true,
  onfidoApplicantId: '',
  onfidoDocumentType: {},
  onfidoToken: '',
  salesforceDocument: {},
  setError: null,
  updateDocumentIndex: null,
};

export default VendorSdk;
